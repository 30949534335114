import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Kanban, PackageDeal, Sequence } from '@stimcar/libs-base';
import type {
  MarketplaceBuyFees,
  MarketplaceOverviewRequest,
  MarketplaceSaleFees,
} from '@stimcar/libs-kernel';
import type { GlobalStoreStateSelector, StoreStateSelector } from '@stimcar/libs-uikernel';
import {
  cloneWithNewIds,
  CoreBackendRoutes,
  EMPTY_CUSTOMER_SPECIFIC_FIELDS,
  KANBAN_ATTRIBUTES,
  kanbanHelpers,
  MARKETPLACE_BUY_PROCESS_PURCHASE_ORDERS,
  MARKETPLACE_SELL_PROCESS_PURCHASE_ORDERS,
  MKTP_PKG_CATEGORY_ADDITIONAL_FEES,
  MKTP_PKG_CATEGORY_FIXED_FEES,
  MKTP_PKG_DEAL_CODE_VARIABLE_FEES,
  nonDeleted,
  packageDealHelpers,
} from '@stimcar/libs-base';
import {
  applyPayload,
  BUY_COMMISSION_RATE,
  computePayload,
  isTruthy,
  MINIMAL_BUY_VARIABLE_FEES,
  MKTP_BUY_PROCESS,
  MKTP_SELL_PROCESS,
} from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import {
  Button,
  InputFormField,
  ModalCardDialog,
  TextAreaFormField,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import { computeKanbanDetailsPath } from '../../coreConstants.js';
import type { MarketplaceOverviewState } from './typings/store.js';
import './MarketplaceOverview.scss';

type CashSentinelValidation = {
  readonly totalSale: number;
  readonly saleSubTotalStimcar: number;
  readonly buySubTotalStimcar: number;
  readonly totalCashSentinel: number;
};

export function formatPriceWithSpacesAndTwoDecimals(number: number): string {
  const price = new Intl.NumberFormat('fr-FR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
  return `${price} €`;
}

function availableAndNonDeleted(pkgDeal: PackageDeal): boolean {
  return pkgDeal.status === 'available' && nonDeleted(pkgDeal);
}

function createMarketplaceBuyerKanban(originalKanban: Kanban, sequence: Sequence): Kanban {
  // First, retrieve every packageDeals and purchaseOrders not related to SELL process
  const nonAllocatedPackageDeals = kanbanHelpers.getNonAllocatedPackageDeals(originalKanban);
  const buyerPackageDeals = kanbanHelpers.getPackageDealsAllocatedToPurchaseNumber(
    originalKanban,
    MARKETPLACE_BUY_PROCESS_PURCHASE_ORDERS
  );
  const purchaseOrdersExceptSell = originalKanban.purchaseOrders.filter(
    (purchaseOrder) => purchaseOrder.purchaseNumber !== MARKETPLACE_SELL_PROCESS_PURCHASE_ORDERS
  );

  // Then, we change ids
  const newPackageDeals = cloneWithNewIds(
    [...nonAllocatedPackageDeals, ...buyerPackageDeals],
    sequence
  );
  const newPurchaseOrders = cloneWithNewIds(purchaseOrdersExceptSell, sequence);

  return {
    ...originalKanban,
    id: sequence.next(),
    customer: EMPTY_CUSTOMER_SPECIFIC_FIELDS,
    attributes: {
      ...originalKanban.attributes,
      [KANBAN_ATTRIBUTES.MKTP_PROCESS]: 'BUY',
    },
    purchaseOrders: newPurchaseOrders,
    packageDeals: newPackageDeals,
  };
}

function updateMarketplaceSellerKanban(originalKanban: Kanban): Kanban {
  const buyerPackageDealIds = kanbanHelpers
    .getPackageDealsAllocatedToPurchaseNumber(
      originalKanban,
      MARKETPLACE_BUY_PROCESS_PURCHASE_ORDERS
    )
    .map(({ id }) => id);

  // We need to delete package deals & purchase orders related to BUY process
  const updatedPackageDeals = originalKanban.packageDeals.map((packageDeal): PackageDeal => {
    if (buyerPackageDealIds.includes(packageDeal.id)) {
      return {
        ...packageDeal,
        deleted: true,
      };
    }
    return packageDeal;
  });
  const updatedPurchaseOrders = originalKanban.purchaseOrders.map((purchaseOrder) => {
    if (purchaseOrder.purchaseNumber === MARKETPLACE_BUY_PROCESS_PURCHASE_ORDERS) {
      return {
        ...purchaseOrder,
        deleted: true,
      };
    }
    return purchaseOrder;
  });

  return {
    ...originalKanban,
    attributes: {
      ...originalKanban.attributes,
      [KANBAN_ATTRIBUTES.MKTP_PROCESS]: 'SELL',
    },
    packageDeals: updatedPackageDeals,
    purchaseOrders: updatedPurchaseOrders,
  };
}

function computePriceWithoutVAT(priceWithVAT: number, VATRate: number): number {
  return priceWithVAT / (1 + VATRate);
}

function computePkgDealsPriceWithVAT(packageDeals: readonly PackageDeal[]): number {
  const priceWithoutVAT = packageDealHelpers.getPriceOperationsAndSpareParts(packageDeals, 'all');
  const VAT = packageDealHelpers.getVATTotalPriceOperationsAndSpareParts(packageDeals, 'all');
  return priceWithoutVAT + VAT;
}

function computeSaleRefurbishFeesWithVAT(kanban: Kanban): number {
  const saleRefurbishPkgDeals = kanbanHelpers
    .getPackageDealsAllocatedToPurchaseNumber(kanban, MARKETPLACE_SELL_PROCESS_PURCHASE_ORDERS)
    .filter(({ marketplaceCategory }) => !isTruthy(marketplaceCategory))
    .filter(availableAndNonDeleted);

  return computePkgDealsPriceWithVAT(saleRefurbishPkgDeals);
}

function computeSaleFixedFeesWithVAT(kanban: Kanban): number {
  const saleFixedCostsPkgDeals = kanbanHelpers
    .getPackageDealsAllocatedToPurchaseNumber(kanban, MARKETPLACE_SELL_PROCESS_PURCHASE_ORDERS)
    .filter(({ marketplaceCategory }) => marketplaceCategory === MKTP_PKG_CATEGORY_FIXED_FEES)
    .filter(availableAndNonDeleted);

  return computePkgDealsPriceWithVAT(saleFixedCostsPkgDeals);
}

function computeRegistrationFeesWithVAT(kanban: Kanban): number {
  const registrationPkgDeals = kanbanHelpers
    .getPackageDealsAllocatedToPurchaseNumber(kanban, MARKETPLACE_BUY_PROCESS_PURCHASE_ORDERS)
    .filter(({ marketplaceCategory }) => marketplaceCategory === MKTP_PKG_CATEGORY_ADDITIONAL_FEES)
    .filter(availableAndNonDeleted);

  return computePkgDealsPriceWithVAT(registrationPkgDeals);
}

function computeBuyRefurbishFeesWithVAT(kanban: Kanban): number {
  const buyRefurbishPkgDeals = kanbanHelpers
    .getPackageDealsAllocatedToPurchaseNumber(kanban, MARKETPLACE_BUY_PROCESS_PURCHASE_ORDERS)
    .filter(({ marketplaceCategory }) => !isTruthy(marketplaceCategory))
    .filter(availableAndNonDeleted);

  return computePkgDealsPriceWithVAT(buyRefurbishPkgDeals);
}

function computeTradeInPriceWithVAT(
  publicPrice: number,
  buyVariableFeesWithVAT: number,
  buyFixedFeesWithVAT: number
): number {
  return publicPrice - buyVariableFeesWithVAT - buyFixedFeesWithVAT;
}

function computeBuyVariableFeesWithVAT(publicPriceWithVAT: number): number {
  const variableFeesWithVAT = publicPriceWithVAT * BUY_COMMISSION_RATE;
  if (variableFeesWithVAT < MINIMAL_BUY_VARIABLE_FEES) {
    return MINIMAL_BUY_VARIABLE_FEES;
  }
  return variableFeesWithVAT;
}

function computeBuyFixedFees(kanban: Kanban): number {
  const buyFixedFeesPkgDeals = kanbanHelpers
    .getPackageDealsAllocatedToPurchaseNumber(kanban, MARKETPLACE_BUY_PROCESS_PURCHASE_ORDERS)
    .filter(({ marketplaceCategory }) => marketplaceCategory === MKTP_PKG_CATEGORY_FIXED_FEES)
    .filter(availableAndNonDeleted);

  return computePkgDealsPriceWithVAT(buyFixedFeesPkgDeals);
}

function computeMarketplaceSaleFees(
  kanban: Kanban,
  tradeInPriceWithVAT: number
): MarketplaceSaleFees {
  const refurbishFeesWithVAT = computeSaleRefurbishFeesWithVAT(kanban);
  const fixedFeesWithVAT = computeSaleFixedFeesWithVAT(kanban);

  const subTotalStimcarWithVAT = refurbishFeesWithVAT + fixedFeesWithVAT;
  const totalWithVAT = tradeInPriceWithVAT - subTotalStimcarWithVAT;
  return {
    tradeInPriceWithVAT,
    refurbishFeesWithVAT,
    fixedFeesWithVAT,
    subTotalStimcarWithVAT,
    totalWithVAT,
  };
}

function computeMarketplaceBuyFees(
  kanban: Kanban,
  tradeInPriceWithVAT: number,
  buyVariableFeesWithVAT: number,
  buyFixedFeesWithVAT: number
): MarketplaceBuyFees {
  const registrationFeesWithVAT = computeRegistrationFeesWithVAT(kanban);
  const refurbishFeesWithVAT = computeBuyRefurbishFeesWithVAT(kanban);

  const subTotalStimcarWithVAT =
    buyVariableFeesWithVAT + buyFixedFeesWithVAT + registrationFeesWithVAT + refurbishFeesWithVAT;
  const totalWithVAT = tradeInPriceWithVAT + subTotalStimcarWithVAT;

  return {
    tradeInPriceWithVAT,
    variableFeesWithVAT: buyVariableFeesWithVAT,
    fixedFeesWithVAT: buyFixedFeesWithVAT,
    registrationFeesWithVAT,
    refurbishFeesWithVAT,
    subTotalStimcarWithVAT,
    totalWithVAT,
  };
}

function computeMarketplaceCashSentinel(
  { totalWithVAT, subTotalStimcarWithVAT: saleSubTotalStimcarWithVAT }: MarketplaceSaleFees,
  { subTotalStimcarWithVAT: buySubTotalStimcarWithVAT }: MarketplaceBuyFees,
  deposit: number
): CashSentinelValidation {
  const buySubTotalStimcar = buySubTotalStimcarWithVAT - deposit;
  const total = totalWithVAT + saleSubTotalStimcarWithVAT + buySubTotalStimcar;
  return {
    totalSale: totalWithVAT,
    saleSubTotalStimcar: saleSubTotalStimcarWithVAT,
    buySubTotalStimcar,
    totalCashSentinel: total,
  };
}

interface LabelWithPriceProps {
  readonly label: string;
  readonly price: number;
  readonly isPrimary?: boolean;
}

function LabelAndPrice({ label, price, isPrimary = false }: LabelWithPriceProps): JSX.Element {
  const size = isPrimary ? 'is-size-6 has-text-weight-bold' : 'is-size-6';
  return (
    <>
      <div className={`${size} is-flex is-justify-content-space-between`}>
        <div>{label}</div>
        <div>{formatPriceWithSpacesAndTwoDecimals(price)}</div>
      </div>
    </>
  );
}

export interface MarketplaceOverviewProps {
  readonly $: StoreStateSelector<Store, MarketplaceOverviewState>;
  readonly $gs: GlobalStoreStateSelector<Store>;
  readonly $selectedKanban: StoreStateSelector<Store, Kanban>;
}

export function MarketplaceOverview({
  $,
  $gs,
  $selectedKanban,
}: MarketplaceOverviewProps): JSX.Element {
  const [t] = useTranslation('details');
  const isOnline = useGetState($gs.$session.$isOnline);
  const kanbanId = useGetState($selectedKanban.$id);
  const kanban = useGetState($selectedKanban);
  const publicSellingPriceAsText = useGetState($.$publicPrice);
  const depositAsText = useGetState($.$deposit);
  const buyer = useGetState($.$buyer);

  const buyVariableFeesWithVAT = useMemo(() => {
    const variableFeesPkgDeal = kanbanHelpers
      .getPackageDealsAllocatedToPurchaseNumber(kanban, MARKETPLACE_BUY_PROCESS_PURCHASE_ORDERS)
      .filter((pkgDeal) => pkgDeal.code === MKTP_PKG_DEAL_CODE_VARIABLE_FEES)
      .filter(nonDeleted);
    return computePkgDealsPriceWithVAT(variableFeesPkgDeal);
  }, [kanban]);
  const buyVariableFeesWithVATAccordingToCurrentPublicPrice = useMemo(() => {
    const publicPriceWithVAT = Number.parseFloat(publicSellingPriceAsText);
    return computeBuyVariableFeesWithVAT(publicPriceWithVAT);
  }, [publicSellingPriceAsText]);

  const buyFixedFeesWithVAT = useMemo(() => computeBuyFixedFees(kanban), [kanban]);
  const tradeInPriceWithVAT = useMemo(() => {
    const publicPrice = Number.parseFloat(publicSellingPriceAsText);
    return computeTradeInPriceWithVAT(publicPrice, buyVariableFeesWithVAT, buyFixedFeesWithVAT);
  }, [publicSellingPriceAsText, buyVariableFeesWithVAT, buyFixedFeesWithVAT]);

  // Compute buy / sale fees
  const saleFees = useMemo(
    () => computeMarketplaceSaleFees(kanban, tradeInPriceWithVAT),
    [kanban, tradeInPriceWithVAT]
  );
  const buyFees = useMemo(
    () =>
      computeMarketplaceBuyFees(
        kanban,
        tradeInPriceWithVAT,
        buyVariableFeesWithVAT,
        buyFixedFeesWithVAT
      ),
    [kanban, tradeInPriceWithVAT, buyVariableFeesWithVAT, buyFixedFeesWithVAT]
  );
  const cashSentinelValidation = useMemo(() => {
    const deposit = Number.parseFloat(depositAsText);
    return computeMarketplaceCashSentinel(saleFees, buyFees, deposit);
  }, [saleFees, buyFees, depositAsText]);

  const paymentDetails = useMemo(() => {
    const deposit = Number.parseFloat(depositAsText);
    return {
      deposit,
      total: buyFees.totalWithVAT,
    };
  }, [buyFees, depositAsText]);

  const saveOverviewValuesToKanban = useActionCallback(
    async ({ actionDispatch, kanbanRepository, getState }): Promise<void> => {
      const { publicPrice, deposit, buyer } = getState();
      const updatedKanban = applyPayload(kanban, {
        attributes: {
          [KANBAN_ATTRIBUTES.MKTP_PUBLIC_SELLING_PRICE]: publicPrice,
          [KANBAN_ATTRIBUTES.MKTP_DEPOSIT]: deposit,
          [KANBAN_ATTRIBUTES.MKTP_BUYER]: buyer,
        },
      });
      // Update the kanban in the repository if needed
      const payload = computePayload(kanban, updatedKanban);
      if (payload !== undefined) {
        await kanbanRepository.updateEntityFromPayload({ entityId: kanbanId, payload });
      }
      actionDispatch.scopeProperty('saveConfirmationDialog').setProperty('active', false);
    },
    [kanbanId, kanban],
    $
  );

  const applyVariableFeesValueToKanban = useActionCallback(
    async ({ actionDispatch, kanbanRepository }): Promise<void> => {
      await actionDispatch.execCallback(saveOverviewValuesToKanban);

      const variableFeesPkgDeal = kanban.packageDeals.find(
        (packageDeal) => packageDeal.code === MKTP_PKG_DEAL_CODE_VARIABLE_FEES
      );
      if (!isTruthy(variableFeesPkgDeal)) {
        throw Error(t('tabs.marketplace.sellProcess.missingVariableFeesPackageDealError'));
      }

      const updatedKanban = applyPayload(kanban, {
        packageDeals: [
          {
            id: variableFeesPkgDeal.id,
            price: computePriceWithoutVAT(buyVariableFeesWithVATAccordingToCurrentPublicPrice, 0.2), // Set price without VAT
          },
        ],
      });
      // Update the kanban in the repository if needed
      const payload = computePayload(kanban, updatedKanban);
      if (payload !== undefined) {
        await kanbanRepository.updateEntityFromPayload({ entityId: kanbanId, payload });
      }
      actionDispatch.scopeProperty('variableFeesConfirmationDialog').setProperty('active', false);
    },
    [
      t,
      kanbanId,
      kanban,
      saveOverviewValuesToKanban,
      buyVariableFeesWithVATAccordingToCurrentPublicPrice,
    ],
    $
  );

  const marketplaceSaleOverviewActionCallback = useActionCallback(
    async ({ actionDispatch, httpClient }): Promise<void> => {
      await actionDispatch.execCallback(saveOverviewValuesToKanban);
      await httpClient.httpPostAsJSON<MarketplaceOverviewRequest, null>(
        CoreBackendRoutes.CREATE_MARKETPLACE_OVERVIEW_DOC(kanbanId, MKTP_SELL_PROCESS),
        { saleFees },
        'POST'
      );
      actionDispatch.scopeProperty('overviewButtonDialog').setProperty('active', true);
    },
    [kanbanId, saleFees, saveOverviewValuesToKanban],
    $
  );

  const marketplaceBuyOverviewActionCallback = useActionCallback(
    async ({ actionDispatch, httpClient }): Promise<void> => {
      await actionDispatch.execCallback(saveOverviewValuesToKanban);
      await httpClient.httpPostAsJSON<MarketplaceOverviewRequest, null>(
        CoreBackendRoutes.CREATE_MARKETPLACE_OVERVIEW_DOC(kanbanId, MKTP_BUY_PROCESS),
        { buyFees, paymentDetails, buyer },
        'POST'
      );
      actionDispatch.scopeProperty('buyGenerationDialog').setProperty('active', false);
      actionDispatch.scopeProperty('overviewButtonDialog').setProperty('active', true);
    },
    [kanbanId, buyFees, paymentDetails, buyer, saveOverviewValuesToKanban],
    $
  );

  const createKanbanForBuyProcessActionCallback = useActionCallback(
    async ({ httpClient, kanbanRepository, navigate }): Promise<void> => {
      const sequence = httpClient.getBrowserSequence();
      const originalKanban = await kanbanRepository.getEntity(kanbanId);
      if (!kanbanHelpers.hasValidMarketplacePurchaseOrders(originalKanban)) {
        throw Error(t('tabs.marketplace.sellProcess.missingMarketplacePurchaseOrdersError'));
      } else {
        const buyerKanban = createMarketplaceBuyerKanban(originalKanban, sequence);
        const updatedSellerKanban = updateMarketplaceSellerKanban(originalKanban);

        await kanbanRepository.createEntity(buyerKanban);
        await kanbanRepository.updateEntity(updatedSellerKanban);

        navigate(computeKanbanDetailsPath(buyerKanban.id));
      }
    },
    [t, kanbanId],
    $
  );

  const openSaveConfirmationModalActionCallback = useActionCallback(
    ({ actionDispatch }): void => {
      actionDispatch.scopeProperty('saveConfirmationDialog').setProperty('active', true);
    },
    [],
    $
  );

  const openVariableFeesConfirmationModalActionCallback = useActionCallback(
    ({ actionDispatch }): void => {
      actionDispatch.scopeProperty('variableFeesConfirmationDialog').setProperty('active', true);
    },
    [],
    $
  );

  const openBuyGenerationModalActionCallback = useActionCallback(
    ({ actionDispatch }): void => {
      actionDispatch.scopeProperty('buyGenerationDialog').setProperty('active', true);
    },
    [],
    $
  );

  const closeOverviewGenerationModalActionCallback = useActionCallback(
    ({ actionDispatch }): void => {
      actionDispatch.scopeProperty('overviewButtonDialog').setProperty('active', false);
    },
    [],
    $
  );

  const closeVariableFeesConfirmationModalActionCallback = useActionCallback(
    ({ actionDispatch }): void => {
      actionDispatch.scopeProperty('variableFeesConfirmationDialog').setProperty('active', false);
    },
    [],
    $
  );

  const closeBuyGenerationModalActionCallback = useActionCallback(
    ({ actionDispatch }): void => {
      actionDispatch.scopeProperty('buyGenerationDialog').setProperty('active', false);
    },
    [],
    $
  );

  const loadValuesFromKanbanAsyncEffect = useActionCallback(
    ({ actionDispatch }): void => {
      actionDispatch.applyPayload({
        publicPrice: String(kanban?.attributes[KANBAN_ATTRIBUTES.MKTP_PUBLIC_SELLING_PRICE] ?? 0),
        deposit: String(kanban?.attributes[KANBAN_ATTRIBUTES.MKTP_DEPOSIT] ?? 0),
        buyer: String(kanban?.attributes[KANBAN_ATTRIBUTES.MKTP_BUYER] ?? ''),
      });
    },
    [kanban],
    $
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadValuesFromKanbanAsyncEffect();
  }, [kanban, loadValuesFromKanbanAsyncEffect]);

  return (
    <>
      <ModalCardDialog
        $active={$.$overviewButtonDialog.$active}
        title={t('tabs.marketplace.overview.modal.generationModalTitle')}
        onOkClicked={closeOverviewGenerationModalActionCallback}
        dontShowCancelButton
      >
        <p>{t('tabs.marketplace.overview.modal.generationModalMessage')}</p>
      </ModalCardDialog>
      <ModalCardDialog
        $active={$.$saveConfirmationDialog.$active}
        title={t('tabs.marketplace.overview.modal.saveConfirmationModalTitle')}
        onOkClicked={saveOverviewValuesToKanban}
        dontShowCancelButton
      >
        <p>{t('tabs.marketplace.overview.modal.saveConfirmationModalMessage')}</p>
      </ModalCardDialog>
      <ModalCardDialog
        $active={$.$variableFeesConfirmationDialog.$active}
        title={t('tabs.marketplace.overview.modal.variableFeesConfirmationModalTitle')}
        onOkClicked={applyVariableFeesValueToKanban}
        onCancelClicked={closeVariableFeesConfirmationModalActionCallback}
      >
        <p>
          {t('tabs.marketplace.overview.modal.variableFeesConfirmationModalMessage', {
            buyVariableFeesWithVAT: formatPriceWithSpacesAndTwoDecimals(
              buyVariableFeesWithVATAccordingToCurrentPublicPrice
            ),
          })}
        </p>
      </ModalCardDialog>
      <ModalCardDialog
        $active={$.$buyGenerationDialog.$active}
        title={t('tabs.marketplace.overview.modal.generationModalTitle')}
        onOkClicked={marketplaceBuyOverviewActionCallback}
        onCancelClicked={closeBuyGenerationModalActionCallback}
      >
        <p>{t('tabs.marketplace.overview.modal.buyGenerationModalMessage')}</p>
        <TextAreaFormField
          label={t('tabs.marketplace.overview.modal.buyerContact')}
          $={$.$buyer}
          horizontal
          rows={3}
        />
      </ModalCardDialog>
      <div className="is-flex is-justify-content-center is-align-items-center mb-4">
        <Button
          iconId="save"
          label={t('tabs.marketplace.saveButton')}
          onClick={openSaveConfirmationModalActionCallback}
          additionalClass="is-primary mx-2 my-2"
          disabled={!isOnline}
        />
        <Button
          iconId="dollar-sign"
          label={t('tabs.marketplace.variableFeesButton')}
          onClick={openVariableFeesConfirmationModalActionCallback}
          additionalClass="is-primary mx-2 my-2"
          disabled={!isOnline}
        />
        <Button
          iconId="clone"
          label={t('tabs.marketplace.cloneForInvoiceButton')}
          onClick={createKanbanForBuyProcessActionCallback}
          additionalClass="is-primary mx-2 my-2"
          disabled={!isOnline}
        />
        <Button
          iconId="file-invoice-dollar"
          label={t('tabs.marketplace.saleOverviewButton')}
          onClick={marketplaceSaleOverviewActionCallback}
          additionalClass="is-primary mx-2 my-2"
          disabled={!isOnline}
        />
        <Button
          iconId="file-invoice-dollar"
          label={t('tabs.marketplace.buyOverviewButton')}
          onClick={openBuyGenerationModalActionCallback}
          additionalClass="is-primary mx-2 my-2"
          disabled={!isOnline}
        />
      </div>
      <div className="columns">
        <div className="column is-9">
          <div className="is-flex">
            <InputFormField
              $={$.$publicPrice}
              type="number"
              label={t('tabs.marketplace.overview.publicPrice')}
              className="has-text-right"
              horizontal
            />
            <div className="is-size-6 ml-2 mt-2">
              {t('tabs.marketplace.overview.publicPriceDefinition')}
            </div>
          </div>
          <table className="table is-striped is-bordered is-hoverable is-fullwidth mx-2">
            <thead>
              <tr>
                <th className="has-text-centered">
                  {t('tabs.marketplace.overview.categories.title')}
                </th>
                <th className="has-text-centered">{t('tabs.marketplace.overview.sale.title')}</th>
                <th className="has-text-centered">{t('tabs.marketplace.overview.buy.title')}</th>
              </tr>
            </thead>
            <tbody>
              <tr key="tradeInPriceRow">
                <td className="is-size-6 has-text-weight-bold">
                  {t('tabs.marketplace.overview.categories.tradeInPrice')}
                </td>
                <td className="is-size-6 has-text-weight-bold has-text-right">
                  {formatPriceWithSpacesAndTwoDecimals(saleFees.tradeInPriceWithVAT)}
                </td>
                <td className="is-size-6 has-text-weight-bold has-text-right">
                  {formatPriceWithSpacesAndTwoDecimals(buyFees.tradeInPriceWithVAT)}
                </td>
              </tr>
              <tr key="fixedFeesRow">
                <td>{t('tabs.marketplace.overview.categories.fixedFees')}</td>
                <td>
                  <LabelAndPrice
                    label={t('tabs.marketplace.overview.common.fixedFees')}
                    price={saleFees.fixedFeesWithVAT}
                  />
                </td>
                <td>
                  <LabelAndPrice
                    label={t('tabs.marketplace.overview.common.fixedFees')}
                    price={buyFees.fixedFeesWithVAT}
                  />
                  <LabelAndPrice
                    label={t('tabs.marketplace.overview.buy.variableFees')}
                    price={buyFees.variableFeesWithVAT}
                  />
                </td>
              </tr>
              <tr key="workshopRow">
                <td>{t('tabs.marketplace.overview.categories.workshop')}</td>
                <td>
                  <LabelAndPrice
                    label={t('tabs.marketplace.overview.sale.refurbishFees')}
                    price={saleFees.refurbishFeesWithVAT}
                  />
                </td>
                <td>
                  <LabelAndPrice
                    label={t('tabs.marketplace.overview.buy.refurbishFees')}
                    price={buyFees.refurbishFeesWithVAT}
                  />
                </td>
              </tr>
              <tr key="additionalFeesRow">
                <td>{t('tabs.marketplace.overview.categories.additionalFees')}</td>
                <td />
                <td>
                  <LabelAndPrice
                    label={t('tabs.marketplace.overview.buy.registrationFees')}
                    price={buyFees.registrationFeesWithVAT}
                  />
                </td>
              </tr>
              <tr key="totalRow">
                <td className="is-size-6 has-text-weight-bold">
                  {t('tabs.marketplace.overview.categories.total')}
                </td>
                <td>
                  <div className="is-size-6 has-text-weight-bold has-text-right">
                    {formatPriceWithSpacesAndTwoDecimals(saleFees.totalWithVAT)}
                  </div>
                  <LabelAndPrice
                    label={t('tabs.marketplace.overview.common.commission')}
                    price={saleFees.subTotalStimcarWithVAT}
                  />
                </td>
                <td>
                  <div className="is-size-6 has-text-weight-bold has-text-right">
                    {formatPriceWithSpacesAndTwoDecimals(buyFees.totalWithVAT)}
                  </div>
                  <LabelAndPrice
                    label={t('tabs.marketplace.overview.common.commission')}
                    price={buyFees.subTotalStimcarWithVAT}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="column is-3">
          <InputFormField
            $={$.$deposit}
            type="number"
            label={t('tabs.marketplace.overview.validation.deposit')}
            className="has-text-right"
            horizontal
          />
          <table className="table is-striped is-bordered is-hoverable is-fullwidth mx-2">
            <thead>
              <tr>
                <th colSpan={2} className="has-text-centered">
                  {t('tabs.marketplace.overview.validation.titleCashSentinel')}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr key="totalSaleRow">
                <td>{t('tabs.marketplace.overview.validation.totalSale')}</td>
                <td className="has-text-right">
                  {formatPriceWithSpacesAndTwoDecimals(cashSentinelValidation.totalSale)}
                </td>
              </tr>
              <tr key="subTotalStimcarSaleRow">
                <td>{t('tabs.marketplace.overview.validation.subTotalStimcarSale')}</td>
                <td className="has-text-right">
                  {formatPriceWithSpacesAndTwoDecimals(cashSentinelValidation.saleSubTotalStimcar)}
                </td>
              </tr>
              <tr key="subTotalStimcarBuyRow">
                <td>
                  <div>
                    {t('tabs.marketplace.overview.validation.subTotalStimcarBuy')}
                    <br />
                    {t('tabs.marketplace.overview.validation.minusDeposit')}
                  </div>
                </td>
                <td className="has-text-right">
                  {formatPriceWithSpacesAndTwoDecimals(cashSentinelValidation.buySubTotalStimcar)}
                </td>
              </tr>
              <tr key="totalCSRow" className="is-size-6 has-text-weight-bold">
                <td>{t('tabs.marketplace.overview.validation.totalCashSentinel')}</td>
                <td className="has-text-right">
                  {formatPriceWithSpacesAndTwoDecimals(cashSentinelValidation.totalCashSentinel)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
